document.addEventListener('DOMContentLoaded', () => {
  // サイトの URL
  const siteUrl = document.querySelector('meta[name="site-url"]').content;

  // フォローしているかどうかを取得
  const isFollow = async (id) => {
    try {
      // 結果を問い合わせる
      const result = await fetch(siteUrl + 'author/is-follow/' + id);

      // 結果をパース
      const response = await result.json();

      // 結果が正常でない場合
      if ( ! result.ok) {
        throw typeof response.errors === 'object' && response.errors !== null ? response : typeof response.message === 'string' ? new Error(response.message) : new Error('エラーが発生しました。恐れ入りますが、画面をリロードしてください。');
      }

      // 結果が含まれていない
      if ( ! ('isFollow' in response)) {
        throw new Error('エラーが発生しました。恐れ入りますが、画面をリロードしてください。');
      }

      // 結果を返す
      return Boolean(response.isFollow);
    }

    // 特に何もしない
    catch (e) {
      console.log(e);
    }

    // フォローしていないことにする
    return false;
  };

  // フォローする or 外す
  const changeSync = async (id, follow) => {
    try {
      // サーバに変更を要請する
      const response = await fetch(siteUrl+'author/'+(follow ? 'set-follow' : 'delete-follow')+'/'+id, {
        method: 'post',
        headers: {
          'X-Requested-With': 'XMLHttpRequest',
          'X-CSRF-TOKEN': document.querySelector('meta[name="csrf-token"]').content
        }
      });

      // エラーがある場合
      if ( ! response.ok) {
        // エラーメッセージを取得
        const result = await response.json();

        // エラーを例外で投げる
        throw typeof result.errors === 'object' && result.errors !== null ? result : typeof result.message === 'string' ? new Error(result.message) : new Error('エラーが発生しました。恐れ入りますが、画面をリロードしてください。');
      }

      // 成功を返す
      return true;
    }

    catch (e) {
      alert(e !== null && typeof e === 'object' && 'message' in e ? e.message : 'エラーが発生しました。恐れ入りますが、画面をリロードしてください。');
    }

    // 失敗を返す
    return false;
  };

  // フォローボタンを作成
  const makeFollowComponent = async (parent) => {
    // 描画位置の条件を満たしていない場合は処理を終了
    if (
      ! (parent instanceof HTMLElement)||
      ! ('id' in parent.dataset) ||
      parent.classList.contains('js-authorFollowComplete')
    ) {
      return;
    }

    // DOM を用意する
    const label = document.createElement('label');
    label.classList.add('lyShrinkNone', 'pCursorPointer', 'pAnimation', 'pHoverOpacityDefault', 'modAuthorFollowChip');
    label.dataset.id = parent.dataset.id;
    const input = document.createElement('input');
    input.type = 'checkbox';
    input.value = parent.dataset.id;
    const span = document.createElement('span');
    span.classList.add('lyPt8', 'lyPb8', 'lyPr6', 'lyPl6', 'pFont12', 'pFontBold', 'pFontWhite', 'pBackColorGray30', 'pBorderRadius2px', 'pAnimation', 'modAuthorFollowChipBtn');
    span.style.whiteSpace = 'nowrap';
    span.textContent = '著者をフォロー';
    label.appendChild(input);
    label.appendChild(span);

    // フォロー状態によって表示を変える関数
    const changeFollow = (follow) => {
      span.textContent = follow ? '著者フォロー中' : '著者をフォロー';
      input.checked = follow;
    };

    // 初期フォロー状態を取得して反映
    const follow = await isFollow(parent.dataset.id);
    changeFollow(follow);

    // クリック時(change イベント)の処理
    input.addEventListener('change', async () => {
      // フォロー状態を変更
      const result = await changeSync(parent.dataset.id, input.checked);
      if (result) {
        changeFollow(input.checked);
      }
      else {
        changeFollow( ! input.checked);
      }
    });

    // 実際に描画
    parent.appendChild(label);

    // 完了フラグを立てる
    parent.classList.add('js-authorFollowComplete');
  }

  // フォローボタン描画位置に応じて描画
  const follows = document.getElementsByClassName('js-authorFollow');
  for (let i = 0; i < follows.length; i = i + 1 | 0) {
    makeFollowComponent(follows.item(i));
  }
});
